<template>
  <q-page>
    <div class="full-width text-left q-px-xl q-py-md bg-indigo-1">
      <div class="text-h6">Import Data Baru</div>
      <!-- import data  -->
      <q-card flat bordered class="full-width q-mt-sm">
        <q-card-section horizontal class="row justify-between">
          <q-card-section class="row q-gutter-sm" style>
            <q-file
              filled
              @input="importFromSheet"
              label="import excel"
              style="width:300px"
              v-model="file"
            >
              <template v-slot:prepend>
                <q-icon name="cloud_upload" @click.stop />
              </template>
            </q-file>
            <q-btn
              dense
              flat
              icon="close"
              @click="file = null; decodedcsv = [];isParsing=false;"
              color="negative"
            ></q-btn>
          </q-card-section>
          <q-card-section class="row items-center">
            <a href="./template_import.xlsx">
              <q-btn no-caps flat color="indigo-10" label="Download Template" />
            </a>
          </q-card-section>
        </q-card-section>
      </q-card>
      <!-- end of import data  -->

      <q-card v-if="isParsing" flat bordered class="q-mt-md">
        <q-markup-table bordered dense class="col-12 stickyTable">
          <thead class="text-blue-grey-14">
            <tr>
              <th style="width:10px;">No</th>
              <th style="width:10px;">No Induk</th>
              <th class="text-left">Nama</th>
              <th class="text-left">Jabatan</th>
              <th class="text-left">Divisi</th>
              <th class="text-center">Jenis Kelamin</th>
              <th class="text-center">Gedung</th>
              <th class="text-center">No Kamar</th>
              <th class="text-center">Guru Kepala</th>
              <th class="text-center">Handphone</th>
            </tr>
          </thead>
          <tbody v-if="decodedcsv.length" class>
            <tr v-for="(d, i) in decodedcsv" :key="i">
              <td class="text-left">{{ i+1 }}</td>
              <td class="text-left">{{ d.no_induk }}</td>
              <td class="text-left">{{ d.nama }}</td>
              <td class="text-left">{{ d.jabatan }}</td>
              <td class="text-left">{{ d.divisi }}</td>
              <td class="text-center" style="width: 100px;">{{ d.jenis_kelamin }}</td>
              <td class="text-center" style="width: 100px;">{{ d.gedung }}</td>
              <td class="text-center" style="width: 100px;">{{ d.no_kamar }}</td>
              <td class="text-center" style="width: 100px;">{{ d.supervisor }}</td>
              <td class="text-center" style="width: 100px;">{{ d.hp }}</td>
            </tr>
          </tbody>
          <tbody v-else class="bg-green-1">
            <tr>
              <td class="text-center text-grey" colspan="99">tidak ada data</td>
            </tr>
          </tbody>
        </q-markup-table>
      </q-card>
      <q-btn
        dense
        unelevated
        color="positive"
        class="q-ma-md"
        style="width: 100px;"
        v-if="isParsing"
        @click="ImportData"
        label="Import Data"
        no-caps
      />
    </div>
  </q-page>
</template>

<style></style>

<script>
import XLSX from "xlsx";

export default {
  name: "Quarantine",
  data() {
    return {
      file: null,

      tab: "guru",
      search: "",
      searchSiswa: "",

      decodedcsv: [],

      orderBy: "",
      orderType: "",
      activePage: 1,
      activePage2: 1,

      databaru: {},
      genderOption: [
        { value: "L", label: "Laki-laki" },
        { value: "P", label: "Perempuan" }
      ],
      isParsing: null,
      confirmDelete: false,
      selectedData: {},
      confirmEdit: false
    };
  },
  mounted() {},
  methods: {
    // import when excel selected
    importFromSheet(file) {
      var reader = new FileReader();
      reader.onload = e => {
        var bin = "";
        var bytes = new Uint8Array(e.target.result);
        var len = bytes.byteLength;

        for (var i = 0; i < len; i++) {
          bin += String.fromCharCode(bytes[i]);
        }

        var wb = XLSX.read(bin, { type: "binary" });
        var name1 = wb.SheetNames[0];
        var name2 = wb.SheetNames[1];
        var name3 = wb.SheetNames[2];
        var ws1 = wb.Sheets[name1];
        var ws2 = wb.Sheets[name2];
        var ws3 = wb.Sheets[name3];
        var json = XLSX.utils.sheet_to_json(ws1);
        json = json.concat(XLSX.utils.sheet_to_json(ws2));
        json = json.concat(XLSX.utils.sheet_to_json(ws3));
        json = this.sanitizeData(json);
        this.decodedcsv = json;
        this.isParsing = true;
      };
      if (!file) return;
      reader.readAsArrayBuffer(file);
    },
    sanitizeData(json) {
      json.forEach(el => {
        el.jabatan = el.jabatan.trim().toUpperCase();
        el.divisi = el.divisi.trim().toUpperCase();
        el.jenis_kelamin = el.jenis_kelamin.trim().toUpperCase();
        if (el.gedung) el.gedung = el.gedung.trim().toUpperCase();
        if (el.hp) {
          if (el.hp[0] == "0") el.hp = el.hp.replace("0", "+62");
        }
      });
      return json;
    },

    // import after data validated
    async ImportData() {
      console.log("importing...");
      this.$q.loading.show({
        message: "Mengimport data, harap tunggu..."
      });
      await this.$http
        .post("/import_data_pokok", this.decodedcsv, {})
        .then(result => {
          this.decodedcsv = [];
          this.isParsing = false;
          this.$q.loading.hide();
          this.$q.notify({
            message: "Berhasil import data",
            color: "positive"
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.my-card {
  min-width: 250px;
  background-color: #b9f6ca;
  color: #9e9e9e;
}
.input-form {
  width: 600px;
}
</style>

import { render, staticRenderFns } from "./ImportData.vue?vue&type=template&id=3ee9d5e2&scoped=true&"
import script from "./ImportData.vue?vue&type=script&lang=js&"
export * from "./ImportData.vue?vue&type=script&lang=js&"
import style1 from "./ImportData.vue?vue&type=style&index=1&id=3ee9d5e2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ee9d5e2",
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QFile from 'quasar/src/components/file/QFile.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QCard,QCardSection,QFile,QIcon,QBtn,QMarkupTable});
